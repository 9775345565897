<template>
  <content justify="center" class="">
    <!-- DIALOGO RESPUESTA INGRESO PEDIDOS -->
    <v-dialog persistent v-model="dialogoRespuestaIngreso" max-width="900">
      <v-card>
        <v-card-title class="info lighten-1 white--text">
          <strong v-if="obteniendo_zonas">Obteniendo zonas...</strong>
          <strong v-else-if="enviando_pedidos"
            >Enviando pedidos a inventario...</strong
          >
          <strong v-else>RESULTADO</strong>

          <v-progress-circular
            class="ml-2"
            indeterminate
            :color="colorProgreso"
          ></v-progress-circular>
        </v-card-title>

        <div class="container">
          <v-row>
            <v-col>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Zonificados: {{ zonificados.length }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in zonificados" :key="item">
                      <td>{{ item }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" colspan="2">
                        No Zonificados: {{ noZonificados.length }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in noZonificados" :key="item">
                      <td>{{ item }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" colspan="2">
                        No están en THOR: {{ noThor.length }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in noThor" :key="item">
                      <td>{{ item }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" colspan="2">
                        Errores: {{ errores.length }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in errores" :key="item">
                      <td>{{ item }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>

        <!-- {{ this.resultadosApi }} -->

        <!-- <v-row class="justify-center">
          <v-col sm="7" md="7" lg="7"> </v-col>
        </v-row> -->
        <v-card-actions class="justify-center">
          <v-btn color="info" @click="dialogoRespuestaIngreso = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- FIN DIALOGO RESPUESTA INGRESO PEDIDOS -->

    <v-form ref="enviarFormulario" v-model="validar">
      <v-row class="d-flex align-center justify-center">
        <v-col cols="12" sm="8" md="7" lg="7" xl="7">
          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7">
              <h3>
                <v-icon size="50" color="info">mdi-database-arrow-right</v-icon>
                Ingreso de pedido
              </h3>
            </v-col>
          </v-row>

          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7">
              <v-autocomplete
                :items="listaRayos"
                item-value="id"
                v-model="rayoSeleccionado"
                item-text="text"
                label="Selecciona Rayo"
                solo
                :rules="rayoRules"
                :loading="cargandoListaRayos"
                required
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" sm="8" md="5" lg="5">
          <v-textarea
            ref="buscador"
            v-model="listaPedidos"
            outlined
            name="input-7-4"
            label=""
            value=""
            required
            :rules="pedidosRules"
          ></v-textarea>
          <v-row justify="center">
            <v-btn
              @click="ingresarPedidos"
              color="success"
              :disabled="!validar"
            >
              Ingresar
            </v-btn>
            <v-btn class="ml-4" color="warning" @click="limpiarDatos">
              Limpiar datos
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </content>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
import moment from "moment";

export default {
  data() {
    return {
      rayoRules: [(v) => !!v || "Debes seleccionar Rayo"],
      pedidosRules: [(v) => !!v || "Debes ingresar al menos un pedido"],
      listaRayos: [],
      rayoSeleccionado: "",
      listaPedidos: "",
      dialogoRespuestaIngreso: false,
      colorProgreso: "",
      validar: false,
      cargandoListaRayos: false,
      fechaActual: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ).toISOString(),
      noZonificados: [],
      zonificados: [],
      noThor: [],
      errores: [],
      obteniendo_zonas: false,
      enviando_pedidos: false,
      fin_enviar: false,
    };
  },
  computed: {
    async obtenerRayos() {
      try {
        console.log("Obteniendo Rayos...");
        this.cargandoListaRayos = true;
        let arrayFleets = [];
        const listaRayos = await axios.get(
          "https://backendservices.rayoapp.com/Rayos/rayos/reduced",
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );

        for (const n in listaRayos.data) {
          arrayFleets.push({
            text: listaRayos.data[n].name_rayo,
            id: listaRayos.data[n].id,
          });
        }

        console.log(listaRayos.status);

        listaRayos.status == 200
          ? console.log("Lista creada!")
          : console.log("No se logró obtener la lista! Revisar API");

        arrayFleets.sort((b, a) => b.text.localeCompare(a.text));
        this.listaRayos = arrayFleets;
        this.cargandoListaRayos = false;
      } catch (error) {
        this.cargandoListaRayos = false;
        console.log(error);
      }
    },
  },

  methods: {
    
    datosUsuario () {
      // console.log(firebase.auth())
      return {
        email_user: firebase.auth().currentUser.email,
        name_user: firebase.auth().currentUser.displayName,
        uid: firebase.auth().currentUser.uid
      }
    },
    limpiarDatos() {
      this.$refs.enviarFormulario.reset();
    },
    validarFormulario() {
      this.$refs.enviarFormulario.validate();
    },
    getTimewindow(task) {
      let cleanTimestamp = null;

      let init_ts = new Date(
        moment().format("YYYY-MM-DD") + " 00:00:00"
      ).getTime();
      let end_ts = new Date(
        moment().add(1, "days").format("YYYY-MM-DD") + " 23:59:59"
      ).getTime();

      if (
        task.dataFound === 0 ||
        !task.time_window ||
        !task.time_window.timestamp ||
        task.time_window.timestamp.length === 0
      ) {
        cleanTimestamp = {
          from: 0,
          to: 23,
          description: "NO TW",
          timestamp: [[init_ts, end_ts]],
        };
      } else {
        cleanTimestamp = task.time_window;
      }

      return cleanTimestamp;
    },
    async ingresarPedidos() {
      this.colorProgreso = "white";
      this.dialogoRespuestaIngreso = true;
      this.noZonificados = [];
      this.zonificados = [];
      this.noThor = [];
      this.errores = [];
      let array_tasks = null;

      let arreglo_pedidos = this.listaPedidos.split(/\r\n|\n|\r/);
      let rayo = "";
      let email = this.datosUsuario().email_user;
      let rayo_id = this.rayoSeleccionado;

      for (const i in this.listaRayos) {
        if (this.rayoSeleccionado == this.listaRayos[i].id) {
          rayo = this.listaRayos[i].text;
        }
      }

      rayo = this.listaRayos.filter((nombreRayo) => nombreRayo.id === rayo_id);

      console.log("CANT DE PEDIDOS: " + arreglo_pedidos.length);
      console.log(`Obteniendo zonas...`);
      this.obteniendo_zonas = true;

      var consultaPedido = await axios.post(
        "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/consultarPedidoIngreso",
        arreglo_pedidos
      );

      let tasks = consultaPedido.data;

      array_tasks = tasks.map((task) => {
        return {
          codigo:
            task.idcustomer_task === "5fbe4f9f48cdb0e97b84ac48"
              ? task.order_id_aux
              : task.order_id_task,
          cliente_corporativo: task.idcustomer_task || "",
          code_task: task.code_task || "",
          estado:
            task.dataFound === 1
              ? "608712c405bfeb80c52f5ef7"
              : "623fa63fece833bb1768336c",
          estado_name: task.dataFound === 1 ? "Open" : "Zoneless",
          posicion_name: task.dataFound === 1 ? "rutas" : "sin-zona",
          posicion:
            task.dataFound === 1
              ? "61d74780c11ae9298749fe02"
              : "6240c77f2c5f3c1ef849c14e",
          latitud: task.latitude_task || "",
          longitud: task.longitude_task || "",
          zona: task.zone_id || "",
          address_task: task.address_task || "",
          time_window: this.getTimewindow(task),
          email: email,
          rayo: rayo,
          _id: rayo_id,
          emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime()
          
        };
      });

      this.obteniendo_zonas = false;
      this.enviando_pedidos = true;

      console.log("LO QUE ENVIO: ", array_tasks);

      let respuestaPeticion = await axios.post(
        "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/ingresar",
        array_tasks
      );

      this.enviando_pedidos = false;

      console.log("LO QUE RECIBO: ", respuestaPeticion.data);

      for (const l in respuestaPeticion.data.zonificados) {
        this.zonificados.push(respuestaPeticion.data.zonificados[l]);
      }
      for (const m in respuestaPeticion.data.noZonificados) {
        this.noZonificados.push(respuestaPeticion.data.noZonificados[m]);
      }
      for (const n in respuestaPeticion.data.noThor) {
        this.noThor.push(respuestaPeticion.data.noThor[n]);
      }
      for (const o in respuestaPeticion.data.error) {
        this.errores.push(respuestaPeticion.data.error[o]);
      }

      this.colorProgreso = "info lighten-1";
    },
  },
  mounted() {
    this.obtenerRayos;
    this.datosUsuario();
  },
};
</script>
